import { observer } from "mobx-react-lite"
import { JSXElementConstructor, useState } from "react"
import { DropOptions } from "react-aria"
import { GridListItemRenderProps, ListBoxItem, Button as RACButton} from "react-aria-components"
import { IUpload, UploadStatus, UploadStore } from "@/stores/UploadStore"
import clsx from "clsx"
import { Check, Clear, CloudUpload, CloudUploadOutlined, Error } from "@material-ui/icons"
import { DropZone, FileTrigger } from "react-aria-components"
import { useMedia } from "@/stores/Media"
import { MediaCard } from "../MediaCard/MediaCard"
import { MediaListContainer } from "../MediaList/MediaList"


export enum MediaType {
    Png = "image/png",
    Jpeg = "image/jpeg",
    Gif = "image/gif",
    Webp = "image/webp",
    Svg = "image/svg+xml",
    Icon = "image/vnd.microsoft.icon",
    Video = "video/*",
    Mp4 = "video/mp4",
    Webm = "video/webm",
    Audio = "audio/*",
    Mp3 = "audio/mpeg",
    Ogg = "audio/ogg",
    Text = "text/*",
    Plain = "text/plain",
    Html = "text/html",
    Markdown = "text/markdown",
    Pdf = "application/pdf",
    Json = "application/json",
    Zip = "application/zip",
    Tar = "application/x-tar",
    Gzip = "application/gzip",
}

export const ImageTypes = [MediaType.Png, MediaType.Jpeg, MediaType.Icon]

export interface MediaUploaderProps extends Omit<DropOptions, 'ref'> {
    uploader?: UploadStore
}

export const MediaUploader = observer((props: MediaUploaderProps) => {
    const [uploader] = useState(() => props.uploader ?? new UploadStore)

    return (
        <div className="flex grow flex-col h-full min-h-0 overflow-hidden">
            <div className="overflow-y-auto min-h-0 shrink grow">
                {uploader.uploads.length > 0 && <MediaListContainer items={uploader.uploads}>
                    {u =>
                        <ListBoxItem>
                            {props => <UploadEntry key={u.key} upload={u} {...props}/>}
                        </ListBoxItem>
                    }
                </MediaListContainer>}
            </div>
            <div className="h-[300px] shrink-0 p-4">
                <MediaDropZone {...props} uploader={uploader} />
            </div>
        </div>
    )
})

export interface MediaDropZoneProps extends Omit<DropOptions, 'ref'> {
    uploader: UploadStore,
}

export const MediaDropZone = observer(({uploader}: MediaDropZoneProps) => {
    const media = useMedia()

    const onSelect = (files: FileList | null) => {
        if (!files) return
        for (const file of files)
            media.upload(file, uploader)
    }

    return (
        <DropZone
            getDropOperation={(types) => ImageTypes.some(t => types.has(t)) ? 'copy' : 'cancel'}
            onDrop={async (e) => {
                for (const item of e.items) {
                    if (item.kind != 'file') continue
                    const file = await item.getFile()
                    media.upload(file, uploader)
                }
            }}
            className={({isDropTarget}) => clsx(
                "flex flex-row h-full w-full border-dashed rounded border-4 overflow-hidden outline-none",
                isDropTarget && "border-blue-500"
            )}
        >
            <FileTrigger onSelect={onSelect} allowsMultiple acceptedFileTypes={ImageTypes}>
                <RACButton className="h-full w-full outline-none">
                    <div className="text-xl font-semibold text-gray-600">Drop an image file here or click</div>
                    <CloudUploadOutlined className="!text-8xl text-gray-300"/>
                </RACButton>
            </FileTrigger>
        </DropZone>
    )
})

const UploadEntry = observer(({upload, ...props}: {upload: IUpload<any>} & GridListItemRenderProps) => {
    const {status, fileInfo: {name}} = upload
    let StatusIcon: JSXElementConstructor<any>
    const isAbortable = [UploadStatus.Created, UploadStatus.Uploading].includes(status)
    if ([UploadStatus.Aborted, UploadStatus.Failed].includes(status)) StatusIcon = Error
    else if (status == UploadStatus.Succeeded) StatusIcon = Check
    else StatusIcon = CloudUpload

    return (
        <MediaCard file={{uri: upload.url, name: upload.fileInfo.name}} {...props}>
            <div className="flex">
                <div>{Math.floor(upload.progress * 100)}%</div>
                <StatusIcon/>
            </div>
        </MediaCard>
    )
})